<template lang="pug">
  .topbar
    .top-row
      .left
        AppDropdown.shop-name(
          close-on-select
          searchable
          :disabled="applyToAllShops"
          :items="shops"
          :value="applyToAllShops ? shops : selectedShop"
          @select="handleSelectShop"
        )
      .right
        //- TODO: uncomment checkbox on work them
        //- AppCheckbox.apply-to-all-shops(
        //-   :value="applyToAllShops"
        //-   :label="$t('plan_settings.settings_by_period.apply_to_all_shops')"
        //-   @change="sync('applyToAllShops', $event)"
        //- )
        SaveButton.save(
          title="actions.save_changes"
          @save="handleSave"
        )
    .bottom-row
      .left
        AppAddNewButton.add-new(
          title="actions.add_new"
          :disabled="disabledAddNew"
          @click="handleAddNew"
        )
        AppSearch.search(
          @update="handleSearch"
        )
      .right
        AppButton.archive(
          title="plan_settings.settings_by_period.archive"
          @click="handleArchive"
        )
</template>

<script>
  export default {
    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      SaveButton: () => import("@/components/elements/AppButton/Save"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch")
    },

    props: {
      shops: {
        type: Array,
        required: true
      },
      selectedShop: {
        type: Object,
        optional: true
      },
      applyToAllShops: {
        type: Boolean,
        default: false
      },
      disabledAddNew: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      sync(fieldName, value) {
        this.$emit(`update:${fieldName}`, value)
      },

      handleSelectShop(value) {
        this.$emit("select-shop", value)
      },

      handleSearch(value) {
        this.$emit("search", value)
      },

      handleAddNew() {
        this.$emit("add-new")
      },

      handleArchive() {
        // TODO: handle archive
      },

      handleSave() {
        this.$emit("save")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .topbar
    margin-top: 20px

    .top-row
      display: flex
      justify-content: space-between

      .left, .right
        display: flex
        align-items: center

      .left
        width: 300px

        ::v-deep
          .app-select
            width: 100%
            margin-left: -7px

      .right
        .apply-to-all-shops
          margin-right: 10px
          zoom: 0.8

  .bottom-row
    margin-top: 10px
    display: flex
    align-items: center
    justify-content: space-between

    .left
      display: flex

      .add-new
        margin-right: 10px

      .search
        width: 300px

    .right
      .archive
        color: $default-purple
        border: 1px solid $default-purple
        height: 38px
        padding: 5px 10px
</style>
